import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import groupBy from "lodash.groupby";

import Layout from "./index";
import SEO from "../components/seo";

function GlossaryLayout({ data }) {
  const terms = data.allMdx.edges;
  const items = terms.map(({node}) => ({
    "id": node.id,
    "value": node.frontmatter.title,
    "key": node.frontmatter.title.charAt(0).toUpperCase(),
    "path": `/glossary/${node.frontmatter.slug}`
  }));
  const grouped = groupBy(items, (item) => item.key);
  return (
    <Layout>
      <SEO keywords={["Agile terms", "Agile Glossary", "Product Management Glossary"]} title="Glossary" />
      <section className="flex flex-col w-full items-center justify-center">
        <div className="w-full text-center bg-mph-light-gray py-16">
          <h1 className="text-2xl sm:text-6xl text-black font-bold tracking-wide">
            Product Management Glossary
          </h1>
        </div>
        <div className="w-full max-w-6xl text-black py-10 text-lg tracking-wide">
          {Object.entries(grouped).map(([key, value]) => {
            return (
              <div key={key} className="w-full py-6">
                <p className="text-xl w-full p-2">
                  {key}
                </p>
                <hr/>
                <div className="w-full flex flex-row flex-wrap items-center justify-start">
                  {value.map((item) => (
                    <div className="w-1/2 sm:w-1/3 md:w-1/4 p-2 text-mph-ocean-blue underline" key={item.id}>
                      <Link to={item.path} className="text-md">
                      {item.value}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Layout>
  );
}

GlossaryLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query query {
    allMdx(filter: {frontmatter: {layout: {eq: "glossary"}}},sort: {fields: frontmatter___title, order: ASC}) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`;

export default GlossaryLayout;
